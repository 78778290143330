export const FirebaseConfig = {
	"projectId": "taxi-2c487",
	"appId": "1:30641057249:web:b3554e778a2c6d4f9bb6a0",
	"databaseURL": "https://taxi-2c487-default-rtdb.firebaseio.com",
	"storageBucket": "taxi-2c487.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDDdZHhNeoQQrSsVkoZ4k5TOh05AIZdYfs",
	"authDomain": "taxi-2c487.firebaseapp.com",
	"messagingSenderId": "30641057249",
	"measurementId": "G-FKGCMG87ZJ"
};